.carouselBoundary {
  position: relative;
  overflow: hidden;
  width: 100%;
}

.carouselWrapper {
  display: flex;
  position: relative;
  width: 100%;
  overflow: hidden;
  z-index: 0; /* Lower stacking priority */
}

.carouselContainer {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none; /* Hide scrollbar */
}

.carouselContainer::-webkit-scrollbar {
  display: none;
}

.carouselItem {
  flex: 0 0 auto;
  /* width: auto; */
  /* padding: 0 40px; */
}

.carouselItemBrands {
  flex: 0 0;
  /* width: auto; */
  padding: 0 40px;
}

/* Navigation Buttons */
.carouselButton {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2; /* Lower z-index to prevent overlapping */
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent */
  backdrop-filter: blur(5px);
  transition: opacity 0.3s ease-in-out;
}

.carouselButtonPrev {
  left: 5px;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  background-color: var(--button-primary);
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.carouselButtonNext {
  right: 5px;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  background-color: var(--button-primary);
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.carouselButton:hover {
  z-index: 3; /* Higher z-index only on hover */
  opacity: 1;
}

/* Hide button when it's not needed */
.carouselButton.hidden {
  display: none;
}
