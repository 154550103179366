.input-container {
  position: relative;
  width: 100%;
}

.input-box {
  /* border: 1px solid #000; */
  padding: 10px;
  /* width: 100%; */
  /* height: 40px; */
  /* font-size: 16px; */
  /* font-family: Arial, sans-serif; */
  box-sizing: border-box;
  outline: none; /* Remove outline */
}

.input-label {
  position: absolute;
  top: 10px;
  left: 10px;
  /* font-size: 16px; */
  /* font-family: "Inter"; */
  /* color: #000; */
  transition: all 0.2s ease-out;
  pointer-events: none;
  background: white; /* Same background as input field */
  padding: 0 5px; /* Space around the label */
}

.input-box:focus + .input-label,
.input-box:not(:placeholder-shown) + .input-label {
  top: -10px; /* Move label above the border */
  left: 20px;
  font-size: 8px;
}

.input-box:focus {
  border: 1px solid #000; /* Border width on focus */
  /* opacity: 50%;
  font-size: 10px; */
}
