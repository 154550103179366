.outer {
  max-width: 1340px;
  align-self: center;
  /* padding: 0px 50px; */
}

.outer-full {
  width: 100%;
  background-color: #f9f9f9;
}

.inner {
  max-width: 1340px;
  align-self: center;
  width: 100%;
  /* padding: 0px 50px; */
}

/* --------------------------------------------------------------------------------------------- */

.footer {
  background-color: #f9f9f9;
  padding: 40px 0;
  margin-top: 100px;
}

.footer-container {
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.footer-left {
  max-width: 300px;
}

.footer-logo-img {
  width: 310px;
  height: 100px;
  object-fit: fill;
  margin-bottom: 20px;
}

.footer-description {
  font-size: 12px;
  margin: 10px 0 20px;
  font-family: "Raleway";
  font-weight: 400;
  color: #000;
  /* letter-spacing: 5px; */
  line-height: 20px;
}

.footer-social {
  display: flex;
  gap: 30px;
}

.footer-icon {
  color: #535353;
  font-size: 20px;
  transition: color 0.3s ease;
  text-decoration: none;
}

/* .footer-icon:hover {
  color: var(--button-primary);
} */

/*
  SOCIAL ICON HOVER COLORS
*/
/*  FACEBOOK */
.fi-fb:hover {
  color: #1877f2;
}

/* EMAIL */
.fi-em:hover {
  color: var(--button-primary);
}

/* INSTAGRAM */
.fi-ig:hover {
  /* color: linear-gradient(45deg, #f09433, #e6683c, #dc2743, #cc2366, #bc1888); */
  background: radial-gradient(
    circle at 30% 110%,
    #ffdb8b 0%,
    #ee653d 25%,
    #d42e81 50%,
    #a237b6 75%,
    #3e57bc 100%
  );
  color: white;
  height: 24px;
  border-radius: 8px;
}

/* LINKEDIN */
.fi-li:hover {
  color: #0072b1;
}

/* YOUTUBE */
.fi-yt:hover {
  color: red;
}

.footer-links {
  display: flex;
  gap: 80px;
  flex-wrap: wrap;
}

.footer-column {
  min-width: 150px;
}

.footer-heading {
  font-size: 20px;
  font-weight: bold;
  font-family: "Raleway";
  margin-bottom: 20px;
  color: #535353;
}

.footer-column ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-column ul li {
  margin-bottom: 15px;
}

.footer-column ul li a {
  color: #434343;
  text-decoration: none;
  font-size: 16px;
  font-family: "Raleway";
  /* font-weight: 500; */
  transition: color 0.3s ease;
}

.footer-column ul li a:hover {
  color: var(--button-primary);
}

/* Responsive Design */
@media (max-width: 1024px) {
  .footer-container {
    flex-direction: column-reverse;
    align-items: center;
    text-align: center;
  }

  .footer-left {
    max-width: 100%;
    text-align: left;
    padding: 0px 20px;
  }

  .footer-logo-img {
    width: 250px;
    margin-bottom: 0px;
  }

  .footer-social {
    justify-content: center;
    /* gap: 20px; */
  }

  .footer-links {
    flex-direction: row;
    gap: 0px;
    flex-wrap: nowrap;
    margin-bottom: 30px;
    /* gap: 30px; */
    /* align-items: center; */
  }

  .footer-column {
    text-align: left;
  }
}

@media (max-width: 600px) {
  .footer-description {
    font-size: 14px;
  }

  .footer-heading {
    font-size: 16px;
  }

  .footer-column li {
    text-align: left;
  }

  .footer-column ul li a {
    font-size: 14px;
  }

  .footer-logo-img {
    width: 240px;
    height: 80px;
    margin-bottom: 0px;
  }
}
