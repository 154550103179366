/* Parent constainer div */
.moodsListingContainer {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin: auto;
  width: 100%;
  margin-top: 75px;
}

.outer {
  max-width: 1340px;
  align-self: center;
}

.outerFull {
  width: 100%;
  background-color: #ece2c3;
  height: max-content;
  padding-top: 15px;
}

.inner {
  max-width: 1340px;
  align-self: center;
  width: 100%;
}

/* moods description section -------------------------------------------------------------------------- */

.moodsListingDescription {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 15px;
}

.moodsHeading {
  font-size: 50px;
  font-family: "Inter";
  font-weight: bold;
  color: #0a0a0a;
  letter-spacing: -1.5px;
}

.moodsSubHeading {
  font-size: 14px;
  font-family: "Inter";
  font-weight: 400;
}

/* moods main banner section -------------------------------------------------------------------------- */

.moodsListingBanner {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 8px;
  padding-left: 30px;
  padding-bottom: 30px;
  height: 351px;
  background-color: aqua;
  border-radius: 15px;
  position: relative;
  background-size: cover;
  background-position: center;
}

.moodsBannerHeading {
  font-size: 56px;
  font-family: "Raleway";
  font-weight: bold;
  color: #ffffff;
  line-height: 40px;
  letter-spacing: -1.5px;
}

.moodsBannerSubHeading {
  font-size: 14px;
  font-family: "Inter";
  font-weight: 400;
  color: #ffffff;
}

/* popular moods section --------------------------------------------------------------- */

.popularMoodsContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 30px;
}

.popularMoodsHeading {
  font-size: 36px;
  font-family: "Raleway";
  font-weight: bold;
  color: #211414;
  letter-spacing: -1px;
}

.popularMoodsCards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /* gap: 10px; */
  margin-top: 10px;
  justify-content: space-between;
  padding-right: 1px;
}

.popularMoodsCard {
  flex: 0 1 calc(25% - 10px);
  box-sizing: border-box;
  height: max-content;
  padding: 0px 0px 30px;
}

.popularMoodsCardImage {
  width: 322px;
  /* width: 100%; */
  height: 166px;
  background-size: cover;
  background-position: center;
  border-radius: 5px;
  box-shadow: 0px 4px 6px #ddd;
}

.popularMoodsCardHeading {
  font-size: 20px;
  font-family: "Inter";
  font-weight: bold;
}

/* allmoods section --------------------------------------------------------------- */

.allMoodsCards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 11px;
  margin-top: 10px;
}

.allMoodsCard {
  height: fit-content;
  margin-bottom: 20px;
}

.allMoodsCardImage {
  width: 258px;
  height: 129px;
  background-size: cover;
  background-position: center;
  border-radius: 5px;
  box-shadow: 0px 4px 6px #ddd;
}

.allMoodsCardHeading {
  font-size: 18px;
  font-family: "Raleway";
  font-weight: bold;
}

/* tablet view css start from here  -------------------------------------------*/
@media screen and (max-width: 768px) {
  .outer {
    max-width: 768px;
    width: 100%;
    padding: 0 10px;
  }
  .inner {
    width: 100%;
  }
  .outerFull {
    padding: 0 10px;
    height: 294px;
  }
  .moodsHeading {
    font-size: 34px;
  }
  .moodsSubHeading {
    font-size: 13px;
  }
  .moodsListingBanner {
    width: 748px;
  }
  .popularMoodsHeading {
    margin-top: 10px;
  }
  .popularMoodsCardImage {
    width: 180px;
    height: 90px;
  }
  .allMoodsCardImage {
    width: 241px;
    height: 230px;
  }
  .allMoodsCard {
    height: auto;
    margin: 0;
  }
}

/* mobile view css start from here  -------------------------------------------*/
@media screen and (max-width: 481px) {
  .outer {
    max-width: 481px;
    width: 100%;
    padding: 0 10px;
  }
  .inner {
    width: 100%;
  }
  .outerFull {
    padding: 0 10px;
    height: auto;
  }
  .moodsHeading {
    font-size: 28px;
  }
  .moodsSubHeading {
    font-size: 12px;
  }
  .moodsListingBanner {
    width: 370px;
  }
  .popularMoodsHeading {
    margin-top: 10px;
  }
  .popularMoodsCardImage {
    width: 177px;
    height: 170px;
  }
  .allMoodsCardImage {
    width: 178px;
    height: 180px;
  }
  .allMoodsCard {
    height: auto;
    margin: 0;
  }
  .popularMoodsCard {
    height: auto;
  }
  .popularMoodsCardHeading {
    margin-bottom: 15px;
  }
  .allMoodsCards {
    justify-content: center;
  }
  .popularMoodsCards {
    gap: 10px;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 375px) {
  .outer {
    max-width: 375px;
    width: 100%;
    padding: 0 10px;
  }
  .inner {
    width: 100%;
  }
  .outerFull {
    padding: 0 10px;
    height: auto;
  }
  .moodsHeading {
    font-size: 22px;
  }
  .moodsSubHeading {
    font-size: 10px;
  }
  .moodsListingBanner {
    width: 340px;
  }
  .popularMoodsHeading {
    margin-top: 10px;
  }
  .popularMoodsCardImage {
    width: 160px;
    height: 160px;
  }
  .allMoodsCardImage {
    width: 160px;
    height: 160px;
  }
  .allMoodsCard {
    height: auto;
    margin: 0;
  }
  .popularMoodsCard {
    height: auto;
  }
  .popularMoodsCardHeading {
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 320px) {
  .outer {
    max-width: 320px;
    width: 100%;
    padding: 0 10px;
  }
  .inner {
    width: 100%;
  }
  .outerFull {
    padding: 0 10px;
    height: auto;
  }
  .moodsHeading {
    font-size: 18px;
  }
  .moodsSubHeading {
    font-size: 10px;
  }
  .moodsListingBanner {
    width: 280px;
  }
  .popularMoodsHeading {
    margin-top: 10px;
  }
  .popularMoodsCardImage {
    width: 170px;
    height: 160px;
  }
  .allMoodsCardImage {
    width: 160px;
    height: 150px;
  }
  .allMoodsCard {
    height: auto;
    margin: 0;
  }
  .popularMoodsCard {
    height: auto;
  }
  .popularMoodsCardHeading {
    margin-bottom: 15px;
  }
}
