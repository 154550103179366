/* .collectionContainer {
  background-color: aqua;
} */

.collectionHeading {
  font-family: "Raleway";
  font-size: 32px;
  font-weight: bold;
  color: #000;
  margin-bottom: 30px;
}

.collContent {
  /* background-color: aqua; */
  /* margin-top: 30px; */
  display: flex;
  flex-direction: row;
  gap: 30px;
  flex-wrap: wrap;
}

.collContentEmpty {
  /* background-color: aqua; */
  /* margin-top: 30px; */
  display: flex;
  flex-direction: column;
  gap: 15px;
  flex-wrap: wrap;
  margin: auto;
}

.playlistCardContainer {
  width: 400px;
  height: 265px;
  /* background-color: #f5baba; */
}

.playlistCard {
  height: 220px;
  width: 400px;
  /* background-color: #363636; */
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  border-radius: 12px;
  padding: 10px;
  position: relative;
  background-size: cover;
  background-position: center;
}

.trackLength {
  height: 64px;
  width: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #fff;
  border-radius: 50px;
}

.trackNumber {
  color: #000;
  font-family: "Inter";
  font-size: 30px;
  font-weight: bolder;
}

.numberHeading {
  color: #000;
  font-family: "Inter";
  font-size: 10px;
  font-weight: bold;
  opacity: 90%;
}

.playlistName {
  font-family: "Raleway";
  font-size: 18px;
  font-weight: 550;
  color: #1c1b1a;
  margin-top: 10px;
}

.trackListContainer {
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
}

.tcardContainer {
  background-color: #fff;
  width: 402px;
  height: 86px;
  border-radius: 4px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 6px;
}

.trackDetails {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;
  padding: 3px 6px;
}

.trackInfo {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
}

.trackHeading {
  font-family: "Inter";
  font-family: 550;
  font-size: 16px;
  color: #000;
}

.deleteIcon {
  display: flex;
  justify-content: flex-end;
  transition: color 0.3s ease; /* Smooth transition */
  color: #b2b2b2;
}

.deleteIcon:hover {
  color: red; /* Change color to red on hover */
}

.trackSubHeading {
  opacity: 50%;
  font-size: 12px;
}

.backToPlaylists {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  margin-bottom: 20px;
  cursor: pointer;
}

.playlistNameActive {
  font-size: 18px;
  font-weight: 550;
  font-family: "Raleway";
}

.emptyHeading {
  /* width: 50%; */
  font-weight: 400;
  font-size: 14px;
  font-family: "Raleway";
  color: #000;
  line-height: 15px;
  margin: 0px auto;
  justify-self: center;
}

.emptyImage {
  height: 358px;
  width: 650px;
  border-radius: 13px;
  margin: 20px auto 0px;
}

.browseBtn {
  margin: 20px auto 0px;
}

.emptyh3 {
  margin: 10px auto 0px;
  justify-self: center;
}