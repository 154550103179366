.outer {
  max-width: 1340px;
  align-self: center;
}

.outerFull {
  width: 100%;
  background-color: #f7f7f7;
  /* min-height: 1300px; */
  height: 100vh;
  overflow-y: auto;
}

.inner {
  max-width: 1020px;
  margin: auto;
  align-self: center;
  width: 100%;
}

.headLogo {
  padding: 10px 0px 0px 10px;
}

.checkoutContainer {
  margin: 25px auto 0px;
  /* padding: 10px 60px; */
  padding: 25px 0px 40px;
}

.checkoutHeader {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.headerText {
  color: #000;
  font-size: 32px;
  font-weight: bold;
  font-family: "Raleway";
}

.billDContainer {
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 100%;
  height: auto;
  padding-top: 10px;
}

.billDetails {
  width: 67%;
  /* background-color: #fff; */
  display: flex;
  flex-direction: column;
  gap: 20px;
  /* width: 600px; */
}

.billSummary {
  width: 100%;
}

.billDHeader {
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.6);
}

.billHeading {
  font-size: 20px;
  font-family: "Raleway";
  font-weight: bold;
  color: #000;
  letter-spacing: -0.5px;
}

.billSubHeading {
  font-size: 12px;
  font-family: "Inter";
  font-weight: 500;
  color: #000;
  opacity: 60%;
  padding-bottom: 5px;
}

.profileInfo {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-bottom: 20px;
}

.profileHeading {
  font-size: 16px;
  font-family: "Raleway";
  font-weight: bold;
  color: #000;
}

.userInfo {
  background-color: #fff;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  /* align-items: center; */
  justify-content: center;
  height: 67px;
  /* box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2); */
  padding-left: 20px;
}

.username {
  font-size: 14px;
  font-weight: 550;
  font-family: "Inter";
  letter-spacing: -0.5px;
}

.emailPhone {
  font-size: 12px;
  font-weight: 550;
  font-family: "Inter";
  opacity: 60%;
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.bullet::before {
  content: "•"; /* Unicode for a bullet */
  margin-right: 2px; /* Space between bullet and text */
  color: black; /* Change bullet color if needed */
}

.billInfoHeading {
  font-size: 16px;
  font-family: "Raleway";
  font-weight: bold;
}

.billInfoContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-bottom: 20px;
}

.billInfoadd {
  background-color: #fff;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  justify-content: center;
  height: 50px;
  /* box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2); */
  padding-left: 20px;
  cursor: pointer;
  letter-spacing: -0.5px;
}

.billInfo {
  background-color: #fff;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 4px;
  /* gap: 8px; */
  /* align-items: center; */
  /* justify-content: center; */
  height: 109px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

.billUserInfo {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 15px;
  padding: 0px 20px;
  padding-top: 20px;
}

/* Change radio button color */
input[type="radio"] {
  accent-color: #666666;
}

.bsiName {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.edit {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}

.gstpanBillInfo {
  display: flex;
  flex-direction: row;
  gap: 4px;
}

.gstpanContainer {
  background-color: #eaf3fd;
  height: 30px;
  display: flex;
  align-items: center;
  margin: 0px 4px;
  border-radius: 4px;
  flex-direction: row;
  gap: 20px;
  padding-left: 45px;
}

.gstpanHeading {
  font-size: 12px;
  font-weight: 550;
  font-family: "Inter";
}

.gstpanNumber {
  font-size: 12px;
  font-weight: 400;
  font-family: "Inter";
}

.orderSumContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.oscHeader {
  font-size: 16px;
  font-family: "Raleway";
  font-weight: bold;
}

.oscContent {
  background-color: #fff;
  height: 71px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px;
  align-items: center;
}

.trackName {
  font-size: 16px;
  font-family: "Raleway";
  font-weight: bold;
}

.artistName {
  font-size: 12px;
  font-family: "Raleway";
  font-weight: 400;
}

.trackPrice {
  font-size: 18px;
  font-family: "Inter";
  font-weight: bold;
  letter-spacing: -1px;
}

.addNewBillingBtn {
  display: flex;
  justify-content: flex-end;
  font-size: 12px;
  font-family: "Inter";
  font-weight: 400;
  cursor: pointer;
}

.trackInfo {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}

.trackQutantity {
  width: 20px;
  height: 20px;
  border-radius: 50px;
  background-color: var(--button-primary);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-family: "Inter";
  font-weight: 400;
  color: #fff;
}

/* bill summarry section -------------------------------------------------------------------- */

.billSummary {
  width: 403px;
  height: 342px;
  border-radius: 10px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  background-color: #fff;
}

.billSumInfo {
  height: 133px;
  padding: 25px;
  padding-top: 30px;
}

.bsiText {
  font-size: 20px;
  font-family: "Raleway";
  font-weight: bold;
  letter-spacing: -0.5px;
}

.bsiSection {
  margin-top: 14px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 14px;
}

.bsiContent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.bsiHeading {
  font-size: 14px;
  font-family: "Raleway";
  font-weight: 400;
}

.bsiSubHeading {
  font-size: 14px;
  font-family: "Inter";
  font-weight: 550;
  color: #0d0d0d;
  letter-spacing: -0.5px;
}

.breakLine {
  color: #000;
  opacity: 50%;
}

.bsiTotalSection {
  padding: 25px;
  padding-top: 20px;
}

.totalAmt {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.totaltext {
  font-size: 24px;
  font-weight: 550;
  font-family: "Raleway";
  letter-spacing: -0.5px;
}

.totalPriceSection {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.billTotal {
  font-size: 24px;
  font-weight: 550;
  font-family: "Inter";
  letter-spacing: -1px;
}

.bsiSaving {
  width: auto;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5d707;
  padding: 8px;
  border-radius: 2px;
  font-size: 13px;
  font-weight: 550;
  font-family: "Inter";
  letter-spacing: -0.5px;
}

.tandc {
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding-left: 25px;
}

.checkboxContainer {
  display: flex;
  align-items: center;
  gap: 8px;
}

.checkbox {
  appearance: none; /* Remove default checkbox styling */
  width: 20px;
  height: 20px;
  border: 1px solid #ccc; /* Default border color */
  border-radius: 2px; /* Optional: rounded corners */
  background-color: #fff; /* Default background */
  cursor: pointer;
  outline: none; /* Remove focus outline */
  transition: background-color 0.3s, border-color 0.3s; /* Smooth transition */
  position: relative; /* Needed for the checkmark pseudo-element */
}

.checkbox:checked {
  background-color: #41ca78; /* Background color when checked */
  border-color: #41ca78; /* Border color when checked */
}

.checkbox:checked::after {
  content: ""; /* Add checkmark */
  position: absolute;
  top: 5%;
  left: 35%;
  width: 22%;
  height: 63%;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(50deg);
  display: block;
}

.checkboxText {
  font-size: 12px;
  color: #000;
  font-family: "Inter";
  font-weight: 400;
}

.link {
  text-decoration: underline;
  color: #000; /* or your desired link color */
}

.payBtn {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.modalBackdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 1000;
  overflow: hidden; /* Prevent scrolling when modal is open */
}

.modalContent {
  background: #fff;
  width: 450px;
  height: 100%;
  border-radius: 4px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  padding: 20px;
  display: flex;
  flex-direction: column;
  animation: slideIn 0.3s ease-out; /* Animation added */
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

.modalHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.headerText {
  font-size: 24px;
  font-family: "Raleway";
  font-weight: 550;
}

.billingForm {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
}

.gstpan {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 8px;
}

.billingCheckbox {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 10px;
}

.phoneInputProfile {
  /* margin-top: 20px; */
  display: flex;
  gap: 10px;
  border: 1px solid #98999c;
  border-radius: 5px;
  padding: 10px;
  align-items: center;
  width: 100%;
  /* background-color: #e2e2e2; */
  /* width: 100%; */
}

.countryCode {
  border: none;
  font-size: 16px;
  background: none;
  border-right: 1px solid #98999c;
}

.phoneNumber {
  border: none;
  outline: none;
  width: 100%;
  font-size: 16px;
}

.state {
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: space-between;
  width: 100%;
}
