.outerPp {
  max-width: 1440px;
}

.outerFullPp {
  width: 100%;
  margin: auto;
  background-color: #f0f6fa;
}

.innerPp {
  width: 1340px;
  margin: auto;
  margin-top: 20px;
}

/* pack details container section ------------------------------------------------------------------------------- */

.pdContainer {
  display: flex;
  flex-direction: column;
  font-family: "Raleway";
  margin-top: 65px;
}

.pdContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #000;
  height: 270px;
  /* padding-left: 30px; */
  gap: 30px;
}

.pdCardImg {
  position: relative;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 425px;
  height: 207px;
  border-radius: 12px;
  float: left;
}

.pdLikeInfo {
  width: 100%;
  height: 100%;
  padding: 0px 20px 20px 20px;
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: end;
  border-radius: 12px;
}

.pdLikeIcon {
  opacity: 50%;
}

.pdCardTrackTotal {
  height: 59px;
  width: 59px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 50px;
  background-color: #fff;
  gap: 0px;
}

.pdCardTrackTotal p {
  margin: -7px;
  opacity: 80%;
  color: #000;
  font-size: 24px;
  font-weight: bolder;
}

.pdCardTrackTotal span {
  margin: 7px;
  opacity: 90%;
  color: #000;
  font-size: 10px;
  font-weight: bold;
}

/* pack details info section -------------------------------------------------- */

.pdInfo {
  display: flex;
  flex-direction: column;
  width: 600px;
  height: 207px;
  gap: 10px;
  float: left;
  margin-left: 30px;
}

.pdInfoTitle {
  font-size: 36px;
  font-weight: bold;
  color: #fff;
}

.pdInfoDescription {
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  opacity: 80%;
  line-height: 20px;
}

.pdInfoPrice {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.pdInfoPriceAmount {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}

.pdDiscountAmount {
  display: flex;
  flex-direction: row;
  color: #fff;
}

.pdDiscountAmount p {
  font-size: 11px;
  font-weight: 400;
  margin: 0px;
  margin-top: 4px;
}

.pdDiscountAmount span {
  font-size: 28px;
  font-weight: bold;
  margin: 0px;
  font-family: "Inter";
}

.pdDiscountPercentage {
  width: 70px;
  height: 23px;
  background-color: #f5d707;
  border-radius: 2px;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Inter";
}

.pdInfoPriceCost {
  font-size: 12px;
  font-weight: 400;
  text-decoration: line-through;
  opacity: 70%;
  color: #fff;
  font-family: "Inter";
}

.pdInfoButton {
  display: flex;
  flex-direction: row;
  gap: 15px;
}

/* track section new css design ---------------------------------------------------- */

.ppTrackCards {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  padding: 0px 0px 20px 20px;
  flex-wrap: wrap;
  background-color: white;
  border-radius: 0px 0px 10px 10px;
  border-bottom: 4px solid #dedede;
  border-right: 1px solid #dedede;
  border-left: 1px solid #dedede;
  /* box-shadow: 0px 0px 8px #0d0d0d4f; */
}

.bgBlack {
  background-image: url("https://hoopr-smash-images.s3.ap-south-1.amazonaws.com/web/default-img/bg-1.png");
  background-repeat: repeat-x;
  background-position: top;
}

.ppTrackHeading {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0px 0px 10px 20px;
  flex-wrap: wrap;
  background-color: white;
  border-radius: 10px 10px 0px 0px;
}

.ppTrackHeading h2 {
  display: flex;
  flex-direction: row-reverse;
  font-size: 14px;
  margin-top: 15px;
}

.buttonContainer {
  text-align: center;
  width: -webkit-fill-available;
  margin-top: 5px;
}

/* pack about section ------------------------------------------------ */

.fl {
  float: left;
}

.about {
  height: 600px;
  width: 440px;
  margin-right: 10px;
  background-color: #fff;
  border-radius: 10px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 40px 40px 10px 40px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.pricing {
  height: 600px;
  width: 890px;
  background-color: #fff;
  border-radius: 10px;
  padding: 40px 40px 10px 40px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  color: #000;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.aboutSongContainer h1 {
  font-size: 16px;
  font-family: "Raleway";
  font-weight: 550;
  color: #000;
  margin-bottom: 30px;
}

.aboutSongItem h2 {
  font-size: 12px;
  font-family: "Raleway";
  font-weight: bold;
  color: #0d0d0d;
  opacity: 60%;
  margin-bottom: 4px;
}

.aboutSongItem h3 {
  font-size: 14px;
  font-family: "Raleway";
  font-weight: 400;
  color: #0d0d0d;
  opacity: 80%;
  margin-bottom: 30px;
  width: 339px;
  /* text-align: justify; */
  line-height: 20px;
}

.aboutSongPillsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 25px;
}

.aboutSongPills {
  margin: 0px;
  font-size: 14px;
  font-family: "Raleway";
  font-weight: 550;
  color: #0d0d0d;
  opacity: 80%;
  width: fit-content;
  background-color: #0d0d0d1a;
  padding: 8px 12px;
  border-radius: 5px;
}

.pricingContainer {
  width: 778px;
  /* border: 1px solid #868686; */
  border-radius: 10px;
  padding: 15px 40px;
  transition: all 0.3s ease;
  overflow: hidden;
  background-color: #f5f5f5;
  box-shadow: 0px 0px 8px #0d0d0d4f;
  margin-left: 15px;
}

.pricingContainer.closed {
  height: 80px; /* Fixed height when closed */
}

.pricingContainer.open {
  max-height: none; /* Allow auto height when open */
  padding: 20px 40px;
}

.pricingDetails {
  padding-top: 30px;
}

.pricingHeaderContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  cursor: pointer;
}

.pricingHeader {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.pricingHeaderPrice {
  display: flex;
  flex-direction: row;
}

.pricingHeaderPrice span {
  font-size: 16px;
  font-family: "Inter";
  font-weight: 400;
  color: #0a0a0a;
  margin-top: 3px;
}

.pricingHeaderPrice p {
  font-size: 26px;
  font-family: "Inter";
  font-weight: bold;
  color: #0a0a0a;
}

.pricingHeader h1 {
  font-size: 24px;
  font-weight: bold;
  font-family: "Raleway";
  color: #01508f;
  /* line-height: 44px; */
}

.pricingHeader h2 {
  font-size: 12px;
  font-weight: 400;
  font-family: "Raleway";
  color: #000;
  line-height: 16px;
}

.arrowContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 23px;
  height: 23px;
  background-color: #eff2f6;
  border-radius: 50px;
}

.pricingTracksBenifits {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding: 10px;
  width: 778px;
}

.pricingbenefitContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 123px;
  height: 108px;
}

.benefitIconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 66px;
  height: 66px;
  background-color: #454545;
  border-radius: 50px;
}

.benefitIconContainer img {
  width: 30px;
  height: 30px;
}

.pricingbenefitContainer h1 {
  color: #0d0d0d;
  font-size: 14px;
  font-weight: 400;
  font-family: "Raleway";
  width: 123px;
  text-align: center;
}

.pricingLongFormDescription {
  font-size: 14px;
  font-family: "Raleway";
  font-weight: 400;
  color: #000;
  width: 657px;
  opacity: 70%;
  margin-bottom: 20px;
}

.shortsSpacing {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.shortProsConsContainer {
  display: flex;
  flex-direction: row;
  gap: 15px;
}

.shortPros {
  width: 337px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.shortProsConsContainer a {
  margin: 0px;
  font-size: 14px;
  font-weight: 400;
  font-family: "Raleway";
  color: #000;
}

.shortsProsButton {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.shortProsItem {
  display: flex;
  gap: 8px;
  width: 337px;
}

.shortProsItem p {
  font-size: 14px;
  font-weight: 400;
  font-family: "Raleway";
  color: #000;
}

.shortConsItem {
  display: flex;
  flex-direction: row;
  width: 337px;
  gap: 8px;
}

.shortConsItem p {
  font-size: 14px;
  font-weight: 400;
  font-family: "Raleway";
  color: #000;
}

.shortCons {
  width: 337px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
