.waveform-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  position: relative;
  z-index: 0;
}

.playPause {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 25px;
}

.player-controls {
  display: flex;
  flex-direction: column;
}

.waveform {
  width: 100%;
  margin-left: 30px;
}

/* Volume Slider */
.volume-slider {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 10px;
  background: linear-gradient(90deg, #f3afaf, #f5174e);
  border-radius: 25px;
  outline: none;
  border: 1px solid #474747;
  box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.3);
}

/* The slider handle (thumb) */
.volume-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  background: white;
  border-radius: 50%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.4);
  cursor: pointer;
  transition: background 0.3s, box-shadow 0.3s;
}

/* Hover effect for the thumb */
.volume-slider::-webkit-slider-thumb:hover {
  background: #f0f0f0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
}

/* Firefox equivalent styling */
.volume-slider::-moz-range-thumb {
  width: 20px;
  height: 20px;
  background: white;
  border-radius: 50%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.4);
  cursor: pointer;
  transition: background 0.3s, box-shadow 0.3s;
}

.volume-slider::-moz-range-thumb:hover {
  background: #f0f0f0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
}
