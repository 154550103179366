.global-player-container {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 1;
  background-color: #000;
  font-family: "Inter";
}

.globalplayer {
  height: 90px;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-grow: 1;
  background-color: #000;
  justify-content: space-between;
}

.g-player-container {
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: center;
}

.g-track-container {
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;
}

.g-track-img {
  height: 62px;
  width: 62px;
  border-radius: 5px;
}

.g-track-info {
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.g-track-info h1 {
  font-size: 16px;
  font-weight: 400;
  color: #f3f5f4;
}

.g-track-info h2 {
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  opacity: 40%;
}

.g-player-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}

.g-waveform-container {
  width: 750px;
  display: flex;
  align-items: center;
}

.g-player-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 18px;
  margin: 0px 15px;
  cursor: pointer;
}

.g-player-close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  z-index: 2;
}

.g-player-close:hover {
  opacity: 0.8;
}

.gp-actions-container {
  margin-right: 100px;
}
