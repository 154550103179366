.purchaseHeaderSection {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.containerHeading {
  font-weight: bold;
  font-size: 32px;
  font-family: "Raleway";
}

.containerSubHeading {
  width: 420px;
  font-weight: 400;
  font-size: 10px;
  font-family: "Inter";
  color: #000;
  opacity: 60%;
  line-height: 14px;
}

.containerPills {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.pills {
  background-color: #fff;
  color: #000;
  padding: 8px 30px;
  border: 1px solid #000;
  border-radius: 50px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 78px;
  max-width: 113px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.pills:hover {
  background-color: #000;
  color: #fff;
}

.selectedPill {
  background-color: #000;
  color: #fff;
  border: none;
}

.cardContainer {
  height: 164px;
  background-color: #fff;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
  width: 100%;
  /* width: 887px; */
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  overflow: hidden; /* To ensure smooth expansion */
  transition: height 0.3s ease; /* Smooth height transition */
}

.cardContainer.expanded {
  height: auto; /* When expanded, the height will adjust based on content */
}

.expandedContent {
  padding: 10px;
  background-color: #f5f5f5;
  margin-top: 10px;
  border-radius: 10px;
  font-size: 14px;
}

.expandedContent {
  padding: 20px;
  margin-top: 0px;
  background-color: transparent;
}

.cardHeader {
  display: flex;
  align-items: center;
  height: 38px;
  padding-left: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  font-size: 16px;
  font-family: "Inter";
  font-weight: bold;
  color: #000;
}

.cardDetails {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-height: 94px;
  padding: 0px 20px;
}

.packCard {
  width: 74px;
  height: 74px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background-size: cover;
  background-position: center;
}

.csdHeader {
  font-size: 10px;
  font-weight: 400;
  font-family: "Inter";
  color: #000;
}

.csdSubHeader {
  font-size: 16px;
  font-weight: bold;
  font-family: "Inter";
  color: #000;
}

.purchaseDetails {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.iconContainer {
  height: 41px;
  width: 41px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  background-color: #e8e8e8;
}

.iconContainerDownload {
  height: 41px;
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  background-color: #e8e8e8;
  gap: 5px;
  color: #696969;
  cursor: pointer;
}

.creditLinks {
  background-color: #000;
  height: 35px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 0px 20px;
  padding-right: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.viewPackTracks {
  background-color: #395b96;
  height: 35px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 0px 20px;
  /* padding-right: 0px; */
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: #fff;
  cursor: pointer;
}

.creditLimit {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.limitNumber {
  height: 23px;
  width: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--button-primary);
  color: #fff;
  border-radius: 50px;
  font-size: 16px;
  font-weight: 400;
  font-family: "Inter";
}

.limitText {
  font-size: 12px;
  font-weight: 400;
  font-family: "Inter";
  color: #fff;
}

.linkText {
  font-size: 14px;
  font-weight: 550;
  font-family: "Raleway";
  color: #fff;
  align-items: center;
  display: flex;
  gap: 5px;
  cursor: pointer;
}

.linkTextAddBtn {
  font-size: 12px;
  font-weight: 550;
  font-family: "Raleway";
  color: #fff;
  align-items: center;
  display: flex;
  gap: 10px;
  cursor: pointer;
  background-color: var(--button-primary);
  padding: 0px 20px;
  height: 100%;
  border-bottom-right-radius: 5px;
}

.cardContainer.expanded .creditLinks {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.useageLinkContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.useageHeader {
  font-size: 16px;
  font-weight: bold;
  font-family: "Raleway";
}

.limitTextExpand {
  font-size: 16px;
  font-weight: 400;
  font-family: "Inter";
  color: #000;
}

.inputContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-top: 10px;
  height: 50px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 20px;
}

.inputSection {
  width: 100%;
}

.inputLabel {
  font-size: 10px;
  font-weight: bold;
  font-family: "Inter";
  color: #000;
  opacity: 50%;
  margin: 0px;
}

.inputHeading {
  font-size: 12px;
  font-weight: 400;
  font-family: "Inter";
  color: #000;
}

.inputBox {
  /* flex: 1; */
  width: 100%;
  /* padding: 10px 0px; */
  font-size: 14px;
  border: none;
  font-family: "Inter";
  font-weight: 400;
  outline: none; /* Removes the default focus outline */
  transition: border-color 0.3s; /* Smooth transition for focus effect */
}

.inputContainer.active {
  border: none; /* Removes border on active input */
}

.iconButton {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  width: 25px;
  border-radius: 50px;
  /* cursor: pointer; */
  transition: background-color 0.3s;
}

.savetext {
  color: var(--button-primary);
  font-weight: bold;
  cursor: pointer;
}

.addlinkText {
  background-color: #000;
  height: 35px;
  border-radius: 50px;
  padding: 0px 20px;
  font-size: 14px;
  font-weight: 550;
  font-family: "Raleway";
  color: #fff;
  align-items: center;
  justify-content: center;
  display: flex;
  gap: 5px;
  cursor: pointer;
}

.packName {
  font-size: 30px;
  font-weight: 550;
  font-family: "Inter";
  margin-top: 40px;
  cursor: pointer;
}

.noData {
  text-align: center;
  margin-top: 30px;
}

.errorMessage {
  color: red;
  font-size: 12px;
  margin-top: 4px;
  margin-left: 10px;
  font-weight: 550;
}

.emptyHeading {
  font-weight: 400;
  font-size: 14px;
  font-family: "Raleway";
  color: #000;
  line-height: 20px;
  margin: 10px auto 0px;
  justify-self: center;
}

.browseBtn {
  margin: 20px auto 0px;
}

.emptyh3 {
  margin: 10px auto 0px;
  justify-self: center;
}

.emptyImage {
  height: 358px;
  width: 650px;
  border-radius: 13px;
  margin: 20px auto 0px;
}

/* confirmation popupmodal */

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContainer {
  width: 809px;
  height: 325px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
  background-color: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);
}

.modalButtons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
}

.mcHeading {
  font-family: "Raleway";
  font-size: 32px;
  font-weight: bold;
  letter-spacing: -1.5px;
  color: #000;
}

.mcSubHeading {
  font-family: "Raleway";
  font-size: 14px;
  color: #545454;
}

.confirmIcon {
  height: 51px;
  width: 51px;
}

.thankyougif {
  height: 150px;
  width: 150px;
}
