.container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin: auto;
  width: 100%;
  margin-top: 75px;
  align-items: center;
}

.outer {
  max-width: 1340px;
  align-self: center;
}

.outerFull {
  width: 100%;
  /* background-color: #ece2c3; */
  /* height: 435px; */
  padding-top: 15px;
}

.inner {
  max-width: 1340px;
  align-self: center;
  width: 100%;
  margin: auto;
}

.topSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* background-color: #ece2c3; */
  height: auto;
  margin: 10px 0px;
  width: 884px;
}

.topHeading {
  font-size: 72px;
  font-family: "Raleway";
  font-weight: bold;
  /* width: 732px; */
  text-align: center;
  margin: 0px 0px 40px;
  line-height: 72px;
}

.subHeading {
  font-size: 18px;
  font-family: "Raleway";
  width: 732px;
  text-align: center;
  margin: 30px 0px;
}

.topImageSection {
  width: 856px;
  height: 482px;
  background-color: #d9d9d9;
  margin: 30px 0px;
  border-radius: 10px;
}

.topImageSection video {
  width: 856px;
  height: 482px;
  border-radius: 10px;
}

.context {
  font-size: 18px;
  font-family: "Raleway";
  margin: 30px 0px;
  line-height: 24px;
}

.trustedBrandSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  margin: 10px 0px;
}

.brandHeading {
  font-family: "Inter";
  font-size: 18px;
  color: #f84451;
  font-weight: 550;
  margin: 20px 0px 20px;
  text-transform: uppercase;
  letter-spacing: 3px;
}

.brandHeadingWCH {
  font-family: "Inter";
  font-size: 18px;
  color: #f84451;
  font-weight: 550;
  margin: 20px 0px 5px;
  text-transform: uppercase;
  letter-spacing: 3px;
}

.brandImagesContainer {
  display: flex;
  flex-direction: row;
  /* gap: 16px; */
  /* background-color: antiquewhite; */
}

.brandImages {
  /* width: 216px; */
  height: 50px;
  /* background-color: #e0e0e0; */
  /* border-radius: 8px; */
}

.wchSection {
  margin: 20px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.wchHeading {
  font-family: "Raleway";
  font-weight: bold;
  font-size: 46px;
  color: #000;
  text-align: center;
  width: 650px;
  line-height: 50px;
  margin: 20px 0px;
}

.wchContext {
  font-size: 18px;
  font-family: "Raleway";
  margin: 0px 0px 30px;
  line-height: 24px;
}

.wchImagesSection {
  display: flex;
  flex-direction: row;
  /* margin: 10px 0px; */
  justify-content: space-between;
  gap: 10px;
}

.wchImg {
  width: 440px;
  height: 525px;
  background-color: #d9d9d9;
  border-radius: 10px;
}

.wchImg img {
  width: 440px;
  height: 525px;
}

.wchImg1 {
  width: 440px;
  height: 629px;
  background-color: #d9d9d9;
  border-radius: 10px;
}

.wchImg1 img {
  width: 440px;
  height: 629px;
}

.wchImg2 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.img2topSection {
  width: 888px;
  height: 309px;
  border-radius: 10px;
  background-color: #d9d9d9;
}

.img2topSection img {
  width: 888px;
  height: 309px;
}

.subimagesSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.subimg1 {
  width: 437px;
  height: 303px;
  border-radius: 10px;
  background-color: #d9d9d9;
}

.subimg1 img {
  width: 437px;
  height: 303px;
}

.hwsSection {
  display: flex;
  flex-direction: column;
  /* background-color: #ece2c3; */
  /* align-items: flex-start; */
  width: 100%;
  margin: 10px 0px;
}

.hwsHeading {
  font-family: "Raleway";
  font-size: 46px;
  font-weight: bold;
  margin: 10px 0px;
}

.stepContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* gap: 10px; */
  margin-top: -1px;
}

.hwsImage {
  /* width: 670px; */
  /* height: 470px; */
  /* border-radius: 30px; */
  /* background-color: #d9d9d9; */
  flex: 1;
}

/* .hwsImage video{
  border-radius: 10px;
} */

.abtSearchImgL {
  /* height: 100%; */
  width: 100%;
  /* pointer-events: none; */
  border-radius: 10px 0px 0px 10px;
}

/* .abtSearchImgL video{
  border-radius: 18px 0px 0px 18px;
} */

.abtSearchImgR {
  /* height: 100%; */
  width: 100%;
  /* pointer-events: none; */
  border-radius: 0px 10px 10px 0px;
}

/* .abtSearchImgR video{
  border-radius: 0px 10px 10px 0px;
} */

.hwsStepText {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  /* background-color: #f84451; */
  /* width: 100%; */
  flex: 1;
  gap: 20px;
}

.stepsIcon {
  display: flex;
  flex-direction: row;
  align-items: start;
  gap: 20px;
  width: 75%;
  margin: 0px auto;
}

.iconContainer {
  width: 48px;
  height: 48px;
  border-radius: 50px;
  background-color: #f84451;
  display: flex;
  align-items: center;
  justify-content: center;
}

.starIcon {
  width: 20px;
  height: 20px;
}

.stepSubHeading {
  width: 430px;
  font-size: 18px;
  font-family: "Raleway";
  width: 75%;
  margin: 0px auto;
}

.heading {
  font-family: "Raleway";
  font-weight: bold;
  font-size: 42px;
}

.advContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  margin: 40px 0px;
}

.advatagesInfo {
  display: flex;
  flex-direction: column;
  width: 33%;
}

.advText {
  color: #ff5555;
  font-size: 18px;
  font-family: "Raleway";
}

.advHeading {
  font-size: 46px;
  font-family: "Raleway";
  font-weight: bold;
}

.advList {
  margin-top: 10px;
}

.advantageImg {
  width: 67%;
  height: 648px;
  border-radius: 10px;
  background-color: #d9d9d9;
}

.advantageImg img {
  width: 100%;
  height: 648px;
  border-radius: 10px;
}

.advStepContainer {
  height: 132px;
  width: 100%;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  padding: 0px 10px;
  border-radius: 10px;
}

.stepHeading {
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  gap: 10px;
}

.advIcon {
  width: 24px;
  height: 24px;
}

.stepHeadingText {
  font-size: 20px;
  font-weight: bold;
  font-family: "Inter";
}

.stepInfoText {
  font-size: 16px;
  font-family: "Raleway";
  opacity: 50%;
  color: #000;
  margin-top: 10px;
}

.highlightAdv {
  color: #fff;
  background-color: #f84451;
  opacity: 100;
}

.highlightAdv p{
  color: #fff;
  background-color: #f84451;
  opacity: 100;
}

.moreContainer {
  background-color: #f5f7fb;
  height: auto;
  width: 100%;
  margin-top: 10px;
  padding: 15px 0px;
}

.moreHeadertext {
  font-family: "Raleway";
  font-weight: bold;
  font-size: 46px;
  /* width: 360px; */
}

.moreSteps {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
}

.msContainer {
  width: 394px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.moreContainerIcon {
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f84451;
  border-radius: 50px;
}

.moreContainerImg {
  width: 30px;
  height: 30px;
}

.moreHeadingtext {
  font-family: "Inter";
  font-weight: bold;
  font-size: 20px;
}

.moreSubHeading {
  font-family: "Raleway";
  font-size: 16px;
  opacity: 50%;
}

.moreCards {
  display: flex;
  flex-direction: row;
  /* gap: 10px; */
  justify-content: space-between;
  margin-top: 30px;
}

.cardContainer {
  /* background-color: #e3e6e8; */
  width: 651px;
  height: 284px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px 30px;
  gap: 25px;
  position: relative;
  background-size: cover;
  background-position: center;
}

.cardContainerBGL {
  width: 651px;
  height: 284px;
  border-radius: 10px;
}

.cardheading {
  width: 420px;
  font-size: 32px;
  font-weight: bold;
  font-family: "Raleway";
  color: #f84451;
}

.cardheadingL {
  width: 420px;
  font-size: 32px;
  font-weight: bold;
  font-family: "Raleway";
  color: #fff;
}

.cardheadingR {
  width: 420px;
  font-size: 32px;
  font-weight: bold;
  font-family: "Raleway";
  color: #000;
}

.cardSubHeading {
  width: 335px;
  font-size: 16px;
  font-family: "Raleway";
  opacity: 50%;
}

.cardSubHeadingL {
  width: 335px;
  font-size: 16px;
  font-family: "Raleway";
  opacity: 50%;
  color: #fff;
}

.cardSubHeadingR {
  width: 335px;
  font-size: 16px;
  font-family: "Raleway";
  opacity: 50%;
  color: #000;
}
