.outer {
  max-width: 1440px;
}

.outer-full {
  width: 100%;
  margin: auto;
  background-color: #f0f6fa;
}

.about {
  height: 630px;
  width: 440px;
  margin-right: 10px;
  background-color: #fff;
  border-radius: 10px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 10px;
  padding: 40px;
}

.pricing {
  height: 630px;
  width: 890px;
  background-color: #fff;
  border-radius: 10px;
  padding-bottom: 10px;
  padding: 40px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  color: #000;
}

.trackDetails {
  background-color: #000;
  color: rgb(255, 255, 240);
  height: 330px;
  padding: 10px;
  margin-top: 65px;
}

.inner {
  width: 1340px;
  margin: auto;
}

.trackPacks {
  margin-top: 10px;
}

.fl {
  float: left;
}

.mbot {
  margin-bottom: 20px;
}

.track-artist-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px 0px 0px 0px;
}

.track-details-container {
  display: flex;
  flex-direction: row;
  gap: 20px;
  height: 202px;
}

.track-image {
  width: 202px;
  height: 100%;
  border-radius: 3px;
  position: relative;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.track-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 15px;
}

.track-names h1 {
  font-size: 32px;
  font-family: "Raleway";
  font-weight: bold;
  color: #fff;
}

.track-names h2 {
  font-size: 12px;
  font-family: "Raleway";
  font-weight: 550;
  color: #fff;
  opacity: 60%;
}

.track-pills {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.track-action-button {
  display: flex;
  flex-direction: row;
  gap: 15px;
  margin: 5px 0px 0px;
}

.about-song-container h1 {
  font-size: 16px;
  font-family: "Raleway";
  font-weight: 550;
  color: #000;
  margin-bottom: 30px;
}

.about-song-item h2 {
  font-size: 12px;
  font-family: "Raleway";
  font-weight: bold;
  color: #0d0d0d;
  opacity: 60%;
  margin-bottom: 4px;
}

.about-song-item h3 {
  font-size: 14px;
  font-family: "Raleway";
  font-weight: 400;
  color: #0d0d0d;
  opacity: 80%;
  margin-bottom: 30px;
  width: 339px;
  text-align: justify;
  line-height: 20px;
}

.about-song-pills-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 25px;
}

.about-song-pills {
  margin: 0px;
  font-size: 14px;
  font-family: "Raleway";
  font-weight: 550;
  color: #0d0d0d;
  opacity: 80%;
  width: fit-content;
  background-color: #0d0d0d1a;
  padding: 8px 12px;
  border-radius: 5px;
}

.pricing-container {
  width: 778px;
  /* border: 1px solid #868686; */
  border-radius: 10px;
  padding: 15px 40px;
  transition: all 0.3s ease;
  overflow: hidden;
  background-color: #f5f5f5;
  box-shadow: 0px 0px 8px #0d0d0d4f;
  margin-left: 15px;
}

.pricing-container.closed {
  height: 80px; /* Fixed height when closed */
}

.pricing-container.open {
  max-height: none; /* Allow auto height when open */
  padding: 20px 40px;
}

.pricing-details {
  padding-top: 30px;
}

.pricing-header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  cursor: pointer;
}

.pricing-header {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.pricing-header-price {
  display: flex;
  flex-direction: row;
}

.pricing-header-price span {
  font-size: 16px;
  font-family: "Inter";
  font-weight: 400;
  color: #0a0a0a;
  margin-top: 3px;
}

.pricing-header-price p {
  font-size: 26px;
  font-family: "Inter";
  font-weight: bold;
  color: #0a0a0a;
}

.pricing-header h1 {
  font-size: 24px;
  font-weight: bold;
  font-family: "Raleway";
  color: #01508f;
  /* line-height: 44px; */
}

.pricing-header h2 {
  font-size: 12px;
  font-weight: 400;
  font-family: "Raleway";
  color: #000;
  line-height: 16px;
}

.arrow-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 23px;
  height: 23px;
  background-color: #eff2f6;
  border-radius: 50px;
}

.pricing-tracks-benifits {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding: 10px;
  width: 778px;
}

.pricingbenefit-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 123px;
  height: 108px;
}

.benefit-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 66px;
  height: 66px;
  background-color: #454545;
  border-radius: 50px;
}

.benefit-icon-container img {
  width: 30px;
  height: 30px;
}

.pricingbenefit-container h1 {
  color: #0d0d0d;
  font-size: 14px;
  font-weight: 400;
  font-family: "Raleway";
  width: 123px;
  text-align: center;
}

.pricing-long-form-description {
  font-size: 14px;
  font-family: "Raleway";
  font-weight: 400;
  color: #000;
  width: 657px;
  opacity: 70%;
  margin-bottom: 20px;
}

.pricing-saving-band {
  background-color: #f3d508;
  width: 778px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  border-radius: 10px;
  font-weight: bold;
  font-size: 14px;
  font-family: "Raleway";
  margin-left: 15px;
}

.pricing-saving-band span {
  padding: 5px 0px;
}

/* --------------------------------------------------------------------------------------------------------------- */

.shorts-spacing {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.short-pros-cons-container {
  display: flex;
  flex-direction: row;
  gap: 15px;
}

.short-pros {
  width: 337px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.short-pros-cons-container a {
  margin: 0px;
  font-size: 14px;
  font-weight: 400;
  font-family: "Raleway";
  color: #000;
}

.shorts-pros-button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.short-pros-item {
  display: flex;
  gap: 8px;
  width: 337px;
}

.short-pros-item p {
  font-size: 14px;
  font-weight: 400;
  font-family: "Raleway";
  color: #000;
}

.short-cons-item {
  display: flex;
  flex-direction: row;
  width: 337px;
  gap: 8px;
}

.short-cons-item p {
  font-size: 14px;
  font-weight: 400;
  font-family: "Raleway";
  color: #000;
}

.short-cons {
  width: 337px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

/* packs with similiar track container -------------------------------------------------------------------------------- */

.track-pack-container {
  display: flex;
  flex-direction: column;
  background-color: #ece2c3;
  padding-bottom: 40px;
  margin-top: 30px;
  height: 454px;
}

.packs-container {
  display: flex;
  flex-direction: column;
  background-color: #ece2c3;
  padding: 30px;
  margin-top: 20px;
  height: 464px;
}

.pack-contianer-heading {
  color: #010101;
  font-size: 24px;
  font-weight: bold;
  font-family: "Raleway";
}

.packs-description-view {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* margin-top: 30px; */
}

.packs-description-view h2 {
  color: #010101;
  font-size: 14px;
  font-weight: 400;
  font-family: "Raleway";
  opacity: 80%;
}

.packs-viewall p {
  color: #373737;
  font-size: 16px;
  font-weight: 550;
  font-family: "Raleway";
  margin: 0;
}

.packs-viewall {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 2px;
}

/* explore similar tracks container ---------------------------------------------------------- */

.similar-track-cards-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  /* margin-top: 30px; */
  padding: 20px 30px 20px 30px;
  width: 100%;
  height: auto;
}

.simHeading {
  font-size: 24px;
  font-weight: bold;
  font-family: "Raleway";
  color: #220700;
}

.similar-track-cards {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-top: 10px;
  padding-bottom: 20px;
}

/* create playlist pop css start from here -------------------------------------------------------------------------------------------------------- */

@keyframes slideInAndShake {
  0% {
    transform: translateY(-100%) translateX(0);
    opacity: 0;
  }
  50% {
    transform: translateY(20px) translateX(-10px);
    opacity: 1;
  }
  70% {
    transform: translateY(10px) translateX(10px);
  }
  90% {
    transform: translateY(0px) translateX(-5px);
  }
  100% {
    transform: translateY(0) translateX(0);
  }
}

.create-playlistContainer {
  position: absolute;
  top: 70px;
  left: 0;
  width: 100%;
  z-index: 1;
  display: flex;
  justify-content: center;
}

.cp-content {
  width: 389px;
  height: auto;
  max-height: 476px;
  background-color: #f1f1f1;
  border-radius: 5px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  /* Animation */
  animation: slideInAndShake 0.4s ease-out;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.popup-heading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.pheadingText {
  font-weight: bold;
  font-family: "Raleway";
  font-size: 26px;
}

.cp-input {
  width: 334px;
  height: 59px;
  border-radius: 8px;
  background-color: #fff;
  font-size: 16px;
  font-family: "Raleway";
  font-weight: 400;
  border: 1px solid #fff;
  padding-left: 15px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  outline: none;
}

.cp-input:focus {
  outline: none;
}

.existing-playlistContainer {
  width: 334px;
  height: 60px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: row;
  padding: 4px;
}

.playlist-heading {
  font-family: "Raleway";
  font-size: 18px;
  font-weight: 550;
  display: flex;
  align-items: center;
  margin-left: 10px;
  gap: 5px;
}

.playlist-imagecard {
  border-radius: 5px;
  width: 80px;
  height: 52px;
  position: relative;
  background-size: cover;
  background-position: center;
}

.existing-playlistsection {
  max-height: 390px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 5px 0px;
}

.no-bg {
  background-color: white;
}