.outer-full-faq {
  width: 100%;
  background-color: transparent;
  margin: 0px;
  margin-top: 80px;
}

.faq-heading {
  text-align: center;
  font-size: 2rem;
  /* margin-bottom: 20px; */
  color: #000;
}

.faq-container {
  /* width: 100%; */
  padding: 20px;
  /* margin: 0 auto; */
}

.faq-section {
  background-color: #303339;
  border-radius: 5px;
  margin-bottom: 15px;
}

.faq-section-header {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  cursor: pointer;
  color: white;
  font-size: 18px;
  height: 50px;
  align-items: center;
}

.faq-section-header.active {
  color: yellow;
}

.icon {
  width: 24px;
  height: 24px;
  transition: transform 0.3s ease;
}

.icon-plus {
  font-size: 20px;
  display: inline-block;
  transition: transform 0.3s ease; /* Smooth rotation transition */
  font-style: normal;
}

.faq-section-header.active .icon {
  transform: rotate(180deg); /* Rotate when the section is open */
}

.faq-questions {
  padding: 10px 20px;
  background-color: #fff;
}

.faq-question {
  margin-bottom: 10px;
}

.faq-question-header {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  color: black;
  font-size: 16px;
  padding: 10px 0;
}

.faq-question-header.active-question {
  font-weight: bold;
}

.faq-answer {
  padding: 10px 0px 10px 10px;
  color: #333;
  border-bottom: 1px solid #ccc;
}
