:root {
  --card-flex: flex;
  --card-gap-container: 15px;

  --card-height: 414px;
  --card-width: 252px;
  --card-background-color: #fff;
  --card-padding: 0px;
  --card-box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.3);

  --card-gap: 0px;

  --image-height: 244px;
  --image-width: 244px;

  --play-btn-width: 60px;
  --play-btn-height: 60px;

  --card-text-height: 164px;
  --card-text-padding: 5px;

  --card-title-font-size: 18px;
  --card-title-font-color: #000;

  --track-title-font-size: 14px;
  --track-title-font-color: #000;

  --card-discount-amt-letter-spacing: -1px;

  --discount-font-letter-space: -1px;

  --discount-font-size: 11px;

  --discount-font-color: #000;

  --discount-p-font-size: 20px;

  --discount-amt-color: #000;

  --discunt-amt-font-weight: 700;

  --track-cost-price-color: #808080;

  --cost-price-font-weight: 550;

  --discount-percentage-font-size-track: 12px;

  --cost-price-font-size: 12px;

  --view-all-p-font-size: 16px;

  --explore-track-gap: 0px;
}
