@import "./playlistvariables.css";
@import "./playlisttype-variables.css";

.playlist-cards-container {
  display: flex;
  flex-direction: row;
  height: var(--packs-container-height);
  gap: 15px;
  margin-top: 0px;
  font-family: "Inter";
  flex-wrap: wrap;
  width: 100%;
}

.pack-card {
  /* flex: 1 1 calc(33.33% - 10px); */
  max-width: var(--pack-card-max-width);
  box-sizing: border-box;
  padding: 10px 0px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0px 0px 8px #ddd;
  margin-top: 10px;
}

.pack-heading {
  color: #030202;
  font-size: var(--pack-title-font-size);
  font-weight: var(--pack-title-font-weight);
  margin-bottom: 5px;
  letter-spacing: -1px;
  /* line-height: 10px; */
}

.pack-title {
  line-height: 15px;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin: 0px 10px;
  padding-right: 20px;
  align-items: baseline;
}

.pack-title span {
  margin-left: 5px;
  margin-top: -2px;
  font-size: 14px;
  font-weight: 550;
  /* font-style: italic; */
  opacity: 90%;
  /* margin-bottom: 5px; */
  /* padding-bottom: 10px; */
}

.pack-card-img {
  position: relative;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--pack-card-img-width);
  height: var(--pack-card-img-height);
  border-radius: 12px;
  margin-top: 7px;
}

.card-like-info {
  /* background-color: rgba(0, 0, 0, 0.5); */
  width: 100%;
  height: 100%;
  padding: 0px 10px 8px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.like-icon {
  opacity: 50%;
}

.card-track-total {
  height: var(--card-track-total-height);
  width: var(--card-track-total-width);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 50px;
  background-color: #fff;
  gap: 0px;
  margin-bottom: 2px;
  line-height: 0.95;
}

.card-track-total p {
  margin: 0px;
  opacity: 80%;
  color: #000;
  font-size: var(--track-total-font-size);
  font-weight: var(--track-total-font-weight);
}

.card-track-total span {
  margin: 0px;
  opacity: 90%;
  color: #000;
  font-size: var(--track-label-font-size);
  font-weight: var(--track-label-font-weight);
}

.pack-price-button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 10px 10px 0px 10px;
}

.pack-price-container {
  display: flex;
  flex-direction: column;
}

.pack-discount-price {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
}

.pack-discount-percentage {
  background-color: #f5d707;
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--discount-percentage-width);
  height: var(--discount-percentage-height);
  border-radius: 2px;
  padding: 10px;
  font-size: var(--discount-percentage-font-size);
  font-weight: var(--discount-percentage-font-weight);
  color: #000000;
  letter-spacing: -1.5px;
}

.pack-discount-amount {
  display: flex;
  flex-direction: row;
  letter-spacing: -1.5px;
}

.pack-discount-amount p {
  margin: 0px;
  color: #030202;
  font-size: var(--discount-amount-font-size);
  font-weight: var(--discount-amount-font-weight);
  letter-spacing: -1.5px;
}

.pack-discount-amount span {
  margin: 0px;
  color: #030202;
  font-size: var(--discount-label-font-size);
  font-weight: var(--discount-label-font-weight);
  margin-top: 4px;
  letter-spacing: -1.5px;
}

.pack-cost-price {
  display: flex;
  flex-direction: row;
  /* letter-spacing: -1.5px; */
}

.pack-cost-price p,
.pack-cost-price span {
  margin: 0px;
  color: #030202;
  opacity: 70%;
  font-size: var(--cost-font-size);
  font-weight: var(--cost-font-weight);
  /* letter-spacing: -1.5px; */
}

.pack-cost-price span {
  text-decoration: line-through;
  /* letter-spacing: -1.5px; */
}

.explore-track-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.explore-pack-btn {
  background-color: #fff;
  border-radius: 50px;
  border: 1px solid var(--button-primary);
  color: var(--button-primary);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 15px;
  width: var(--explore-btn-width);
  height: var(--explore-btn-height);
  font-size: var(--explore-btn-font-size);
  font-weight: var(--explore-btn-font-weight);
}

.explore-pack-cart-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--button-primary);
  width: var(--cart-btn-width);
  height: var(--cart-btn-height);
  border-radius: 50px;
  border: none;
}
