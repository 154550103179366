.parentContainer {
  overflow: hidden;
  margin: auto;
  width: 100%;
  margin-top: 85px;
  font-family: "Raleway";
  min-height: 100vh;
}

.outer {
  max-width: 800px;
  align-self: center;
}

.outerFull {
  width: 100%;
  background-color: #ece2c3;
  height: 300px;
  padding-top: 15px;
}

.inner {
  max-width: 1340px;
  align-self: center;
  width: 100%;
}

.formContainer {
  display: flex;
  flex-direction: column;
  gap: 15px;
  /* background-color: aqua; */
  height: 506px;
  width: 397px;
  /* margin-top: 20px; */
}

.contentSection {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.imageSection {
  position: relative;
  background-size: cover;
  background-position: center;
  background-color: #d9d9d9;
  height: 506px;
  width: 397px;
  border-radius: 10px;
  padding: 25px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.isHeadingtext {
  font-size: 32px;
  font-weight: bold;
  font-family: "Raleway";
}

.isSubHeadingText {
  font-size: 14px;
  /* font-weight: bold; */
  font-family: "Raleway";
}

.phoneInput {
  /* margin-top: 20px; */
  display: flex;
  gap: 0px;
  border: 1px solid #bfbfbf;
  border-radius: 5px;
  /* padding: 0px 15px; */
  align-items: center;
}

.countryCode {
  border: none;
  font-size: 18px;
  background: none;
  border-right: 1px solid #bfbfbf;
  padding: 0px 10px;
  height: 50px;
  background-color: #fff;
}

.phoneNumber {
  border: none;
  outline: none;
  width: 100%;
  height: 50px;
  font-size: 18px;
  padding: 0px 10px;
  letter-spacing: 1px;
  border-radius: 4px;
}

.textareaContainer {
  /* margin: 16px 0; */
}

.textarea {
  width: 100%;
  height: 150px;
  padding: 8px;
  border: 1px solid #bfbfbf;
  border-radius: 4px;
  font-size: 16px;
  font-family: Inter;
  resize: none;
}

.errorMsg {
  color: red;
  font-size: 14px;
  margin-top: -8px;
  font-weight: bold;
}

.detailsSection {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 40px;
  gap: 50px;
}

.dsHeader {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.dsheadingText {
  font-size: 14px;
  font-family: "Raleway";
  font-weight: bold;
  color: #000;
}

.dssubHeading {
  font-size: 14px;
  font-family: "Raleway";
  color: #000;
  opacity: 70%;
  width: 250px;
  line-height: 18px;
}

.iconcontainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.icon {
  width: 17px;
  height: 17px;
}
