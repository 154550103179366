.filter-contents {
  width: 218px;
  height: 100%;
  /* background-color: #683036; */
}

.filter-options {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  gap: 20px;
}

.filter-track-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.filter-track-title p {
  font-size: 14px;
  font-weight: 700;
  font-family: "Raleway";
}

.filter-track-title span {
  font-size: 12px;
  font-weight: 550;
  font-family: "Raleway";
  text-decoration: underline;
  cursor: pointer;
}

.filter-artist {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 25px;
  margin-left: 5px;
}

.filter-artist p {
  font-size: 14px;
  font-weight: 550;
  font-family: "Raleway";
  color: #000;
  opacity: 70%;
}

.filter-artist-options {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
