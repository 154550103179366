.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 1000;
}

.modalOverlayWithPlayer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 90px); /* Adjusted height when global player is active */
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 1000;
}

.modalContainer {
  width: 450px;
  height: 100%;
  background-size: cover;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  /* justify-content: flex-end; */
  align-items: center;
  background-color: #f7f7f7;
  animation: slideIn 0.3s ease-out; /* Animation added */
}

.heading {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}

.header {
  /* height: 60px; Fixed height */
  flex: 0 0 60px; /* Fixed height, won't shrink */
  width: 100%;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);
}

.headingtext {
  font-size: 26px;
  font-family: "Raleway";
  font-weight: bold;
}

.cartCount {
  height: 28px;
  width: 28px;
  background-color: var(--button-primary);
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  font-family: "Inter";
}

.billItems {
  flex-grow: 1; /* Make it expand to fill the available space */
  width: 100%;
  /* background-color: bisque; */
  overflow-y: auto; /* Add scrolling if the content exceeds available space */
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5px 0px;
  padding: 10px 0px;
  gap: 20px;
}

.cardContainer {
  width: 405px;
  height: 148px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);
}

.cardDetails {
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding: 10px;
  height: 120px;
  border-bottom: 1px solid rgba(156, 156, 156, 0.5);
}

.alignCenter {
  align-items: center;
}

.cardInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.cardPriText {
  font-family: "Raleway";
  font-size: 18px;
  font-weight: bold;
}

.cardSectext {
  font-family: "Raleway";
  font-size: 14px;
  font-weight: 400;
  opacity: 60%;
  /* margin-top: 2px; */
}

.itemControls {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
}

.itemIncBtn {
  border: 1px solid #b2b2b2;
  border-radius: 20px;
  height: 22px;
  width: 94px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 8px;
}

.deleteIcon {
  transition: color 0.3s ease; /* Smooth transition */
  color: #b2b2b2;
}

.deleteIcon:hover {
  color: red; /* Change color to red on hover */
}

.itemCount {
  font-size: 12px;
  font-weight: 550;
  font-family: "Inter";
  color: #000;
}

.trackImage {
  border-radius: 5px;
  width: 100px;
  height: 100px;
}

.packImage {
  border-radius: 5px;
  width: 100px;
  height: 50px;
}

.itemTotalPrice {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px;
  height: 28px;
}

.cardTotalPriceText {
  font-family: "Raleway";
  font-size: 12px;
  font-weight: 550;
  color: #000;
  opacity: 60%;
}

.cardTotalPrice {
  font-size: 16px;
  font-weight: bold;
  font-family: "Inter";
}

.billSummary {
  height: 29px;
  width: 100%;
  background-color: #363636;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0px 20px;
}

.billInfo {
  height: auto;
  /* background-color: bisque; */
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.billDetail {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.detailHeading {
  font-size: 14px;
  font-family: "Raleway";
  font-weight: 400;
  color: #0d0d0d;
}

.price {
  font-size: 16px;
  font-weight: 400;
  color: #0d0d0d;
  font-family: "Inter";
}

.sumHeadingText {
  color: #fff;
  font-family: "Raleway";
  font-weight: bold;
  font-size: 14px;
}

.bllingTotal {
  height: 84px;
  width: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0px 20px;
  border-top: 1px solid #363636;
}

.totalCost {
  font-size: 28px;
  font-family: "Inter";
  font-weight: bold;
}

.savings {
  background-color: #f5d707;
  width: auto;
  height: 20px;
  padding: 0px 6px;
  border-radius: 3px;
  color: #000;
  font-size: 12px;
  font-family: "Inter";
  font-weight: 550;
  display: flex;
  align-items: center;
  /* justify-content: center; */
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

.Emptycontainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  font-family: "Raleway";
  margin-top: 20%;
  padding: 0px 20px;
  text-align: center;
}

.EmptycartImg {
  width: 104px;
  height: 104px;
}

.Emptyheading {
  font-weight: bold;
  font-size: 24px;
  color: #000;
}

.EmptysubHeading {
  font-weight: 400;
  font-size: 14px;
  color: #000;
}
