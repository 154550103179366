.packs-cards-container[data-type="allpacks"] {
  --packs-container-height: max-content;

  /* --explore-btn-width: 125px; */

  --pack-title-font-size: 16px;
  --pack-title-font-weight: 550;

  /* --pack-card-img-width: 345px; */
  --pack-card-img-width: 436px;
  --pack-card-img-height: 220px;

  --card-track-total-height: 50px;
  --card-track-total-width: 50px;

  --track-total-font-size: 24px;
  --track-total-font-weight: bolder;
  --track-label-font-size: 8px;
  --track-label-font-weight: bold;

  --discount-percentage-width: 55px;
  --discount-percentage-height: 17px;
  --discount-percentage-font-size: 11px;
  --discount-percentage-font-weight: bold;

  --discount-amount-font-size: 22px;
  --discount-amount-font-weight: bold;
  --discount-label-font-size: 11px;
  --discount-label-font-weight: 400;

  --cost-font-size: 10px;
  --cost-font-weight: 400;

  --explore-btn-width: 110px;
  --explore-btn-height: 35px;
  --explore-btn-font-size: 12px;
  --explore-btn-font-weight: bold;

  --cart-btn-height: 37px;
  --cart-btn-width: 37px;
}

.packs-cards-container[data-type="similartrackpack"] {
  --packs-container-height: 303px;

  --pack-title-font-size: 20px;
  --pack-title-font-weight: 550;

  --pack-card-img-width: 438px;
  --pack-card-img-height: 214px;
}

.packs-cards-container[data-type="savemoretrack"] {
  --packs-container-height: 229px;

  --pack-card-max-width: 336px;

  --pack-title-font-size: 16px;
  --pack-title-font-weight: bold;

  --pack-card-img-width: 332px;
  --pack-card-img-height: 161px;

  --card-track-total-height: 50px;
  --card-track-total-width: 50px;

  --track-total-font-size: 22px;
  --track-total-font-weight: bolder;
  --track-label-font-size: 8px;
  --track-label-font-weight: bold;

  --discount-amount-font-size: 18px;
  --discount-amount-font-weight: bold;
  --discount-label-font-size: 11px;
  --discount-label-font-weight: 400;
}

.packs-cards-container[data-type="toppackhome"] {
  --pack-card-img-width: 436px;
  --explore-btn-width: 125px;
}

.packs-cards-container[data-type="likespacks"] {
  --pack-card-img-width: 365px;
  --pack-card-max-width: 365px;
  --explore-btn-width: 105px;
}
