.search-container {
  position: relative;
  width: 100%;
}

.search-bar {
  display: flex;
  align-items: center;
  border: 1px solid #d6d8de;
  border-radius: 30px;
  padding: 10px;
  /* width: 332px; */
  /* height: 44px; */
  box-sizing: border-box;
  position: relative;
  transition: border-radius 0.3s ease;
}

.search-bar.icon-start .search-input {
  margin-left: 10px;
}

.search-bar.icon-start .search-label {
  margin-left: 15px;
}

.search-icon {
  color: #000;
  margin-right: 10px;
}

.search-input {
  flex: 1;
  border: none;
  outline: none;
  font-size: 16px;
  margin-left: 15px;
}

.search-label {
  position: absolute;
  top: 12px;
  left: 17px;
  /* font-size: 16px; */
  color: #919191;
  pointer-events: none;
  transition: all 0.2s ease;
  background: white;
  padding: 0 5px;
}

.search-input:focus + .search-label,
.search-input:not(:placeholder-shown) + .search-label {
  top: -10px;
  font-size: 12px;
}

/* Dropdown */
.dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background: white;
  border: 1px solid #ccc;
  list-style-type: none;
  padding: 0;
  margin: 0;
  z-index: 10;
  max-height: 200px;
  overflow-y: auto;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.dropdown-open .search-bar {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.dropdown li {
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.dropdown li.active {
  background-color: #f0f0f0;
}

.dropdown li:hover {
  background-color: #f0f0f0;
}

.no-data {
  padding: 10px;
  color: #919191; /* Gray color for "No data found" */
  text-align: center;
}

@media (max-width: 768px) {
  .search-bar {
    width: 100%;
  }
}
