/* Default styles for all track cards */
.allsong-track-card-container {
  --card-gap-container: 20px;
  --card-height: 414px;
  --card-width: 252px;
  --card-padding: 0px;
  --card-text-padding: 0 10px;
  --card-text-height: 150px;
  --card-gap: 10px;

  --play-btn-width: 60px;
  --play-btn-height: 60px;

  --image-shadow: 0px 6px 6px rgba(0, 0, 0, 0.3);

  --card-discount-amt-letter-spacing: -1.5px;
  --discount-font-letter-space: -1.5px;
  --discount-percentage-font-size-track: 14px;

  --discount-amt-font-weight: bold;
  --cost-price-font-weight: 400;
  --cost-price-font-size: 13px;
  --view-all-p-font-size: 16px;
  --explore-track-gap: 5px;
}

.allsong-track-card-container[data-type="alltracks"] {
  --card-gap-container: 19px;
}

/* Feature & Indie cards (same background and box-shadow) */
.allsong-track-card-container[data-type="feature"],
.allsong-track-card-container[data-type="indie"] {
  --card-background-color: transparent;
  --card-box-shadow: none;

  --card-title-font-color: #fff;
  --discount-amt-color: #fff;
  --discount-font-color: #fff;
  --track-cost-price-color: #fff;
  --track-title-font-color: #fff;
}

/* Contemporary, For You, and Packs cards (white background) */
.allsong-track-card-container[data-type="contemporary"],
.allsong-track-card-container[data-type="searchTrack"],
.allsong-track-card-container[data-type="foryou"],
.allsong-track-card-container[data-type="packs"] {
  --card-background-color: #fff;
  --card-title-font-color: #000;
  --discount-amt-color: #000;
  --discount-font-color: #000;
  --track-cost-price-color: #000;
  --track-title-font-color: #000;
}

/* Contemporary card specific styles */
.allsong-track-card-container[data-type="contemporary"],
.allsong-track-card-container[data-type="searchTrack"] {
  --card-discount-amt-letter-spacing: -1px;
  --discount-font-letter-space: -0.75px;
  --discount-percentage-font-size-track: 12px;
}

/* Indie card specific styles */
.allsong-track-card-container[data-type="indie"] {
  --card-title-font-color: #000;
  --discount-amt-color: #000;
  --discount-font-color: #000;
  --track-cost-price-color: #000;
  --track-title-font-color: #000;
}

/* Packs card (smaller size, no image shadow) */
.allsong-track-card-container[data-type="packs"] {
  --card-gap-container: 10px;
  --card-height: 310px;
  --card-width: 250px;
  --image-shadow: none;
  --card-text-height: auto;
}

/* For You card (unique image size) */
.allsong-track-card-container[data-type="foryou"] {
  --image-height: 244px;
  --image-width: 234px;
  --card-width: 244px;
}

@media screen and (max-width: 768px) {
  .allsong-track-card-container {
    --card-height: 380px;
    --card-width: 228px;
    --image-width: 218px;
    --image-height: 218px;
    --track-title-font-size: 12px;
  }
}

@media screen and (max-width: 481px) {
  .allsong-track-card-container {
    --card-height: 320px;
    --card-width: 166px;
    --image-width: 160px;
    --image-height: 160px;
    --track-title-font-size: 9px;
    --card-title-font-size: 14px;
    justify-content: center;
  }
  .allsong-track-card-container[data-type="searchTrack"] {
    --card-width: 164px;
  }
}

@media screen and (max-width: 375px) {
  .allsong-track-card-container {
    --card-height: 316px;
    --card-width: 164px;
    --image-width: 154px;
    --image-height: 154px;
    --track-title-font-size: 9px;
    --card-title-font-size: 14px;
    --card-gap-container: 20px;
    justify-content: center;
  }
  .allsong-track-card-container[data-type="searchTrack"] {
    --card-height: 300px;
    --card-width: 150px;
    --image-width: 140px;
    --image-height: 140px;
    --track-title-font-size: 8px;
    --card-title-font-size: 12px;
  }
}

@media screen and (max-width: 320px) {
  .allsong-track-card-container {
    --card-height: 300px;
    --card-width: 142px;
    --image-width: 132px;
    --image-height: 132px;
    --track-title-font-size: 8px;
    --card-title-font-size: 12px;
    justify-content: center;
  }
  .allsong-track-card-container[data-type="searchTrack"] {
    --card-height: 265px;
    --card-width: 130px;
    --image-width: 122px;
    --image-height: 122px;
    --discount-p-font-size: 16px;
    --cost-price-font-size: 11px;
    --card-title-font-size: 11px;
    --track-title-font-size: 6px;
  }
}
